import * as React from "react";
import type { VariantProps } from "tailwind-variants";
import { tv } from "tailwind-variants";

import { cn } from "~/lib/ui";

const alertVariants = tv({
  base: "relative w-full rounded-small-5 border p-3 text-sm align-center [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-3 [&>svg~*]:pl-6",
  variants: {
    variant: {
      accent: "border-accent-6 bg-accent-2 text-text-dark-on-light",
      neutral: "bg-neutralalpha-3 text-neutralalpha-11",
      error: "border-erroralpha-6 bg-erroralpha-2 text-erroralpha-11",
      success: "bg-successalpha-3 text-successalpha-11",
      warning: "border-warningalpha-6 bg-warningalpha-2 text-warningalpha-11",
      info: "bg-accentalpha-3 text-dark-on-light",
    },
  },
  defaultVariants: {
    variant: "warning",
  },
});

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants>
>(({ className, variant, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(alertVariants({ variant }), className)}
    {...props}
  />
));
Alert.displayName = "Alert";

// ---

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h5
    ref={ref}
    className={cn("font-medium leading-none", className)}
    {...props}
  />
));
AlertTitle.displayName = "AlertTitle";

// ---

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("mt-1", className)} {...props} />
));
AlertDescription.displayName = "AlertDescription";

// ---

const AlertDetailsList = React.forwardRef<
  HTMLUListElement,
  React.HTMLAttributes<HTMLUListElement>
>(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    className={cn("mt-2 list-inside list-disc", className)}
    {...props}
  />
));
AlertDetailsList.displayName = "AlertDetailsList";

// ---

const AlertDetailItem = React.forwardRef<
  HTMLLIElement,
  React.HTMLAttributes<HTMLLIElement> & { name?: string; hideName?: boolean }
>(({ className, name, hideName, children, ...props }, ref) => (
  <li ref={ref}>
    {name && !hideName && (
      <span className="font-medium" {...props}>
        {name}:{" "}
      </span>
    )}
    {children}
  </li>
));
AlertDetailItem.displayName = "AlertDetailItem";

export {
  Alert,
  AlertDescription,
  AlertDetailItem,
  AlertDetailsList,
  AlertTitle,
};
